import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "장애인 이동권, 갈등 넘어 연대로",
  "slug": "data14",
  "date": "2022-07-10T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb14.png",
  "featuredImg": "../images/featured/featured14.png",
  "summary": "장애인 이동권 관련 장애·비장애인 같이 숙의토론해보았다.",
  "tags": ["정책", "장애인", "이동권"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`서울신문은 2022년 7월 비영리 공공조사 네트워크 공공의창, 여론조사기관 우리리서치와 함께 시민 800명에게 장애인 이동권과 시위에 대한 의견을 물었다. 그 결과 58.3%가 ‘장애인의 대중교통 탑승은 당연한 일이다. 개인 일정에 차질이 생겨도 감수할 수 있다’고 답했다. ‘불편을 감수 못 하겠다’는 32.9%였다. 시위에 대한 공감을 넘어 시민 53.5%는 ‘장애인 이동권 이슈가 우리 사회를 보다 건강하게 만들 것’이라고 평가했다. ‘갈등 사회로 만들 것’이라는 응답은 32.4%에 그쳤다.`}</p>
    <p>{`또한 ‘장애인이 되거나 거동이 불편해졌을’ 상황을 전제로 현 이동권 보장 수준에 만족하는지 물었더니 67.6%가 ‘만족하지 못할 것 같다’고 답했다. 장애인 생활·활동 여건 수준에 대해서는 65.5%가 ‘좋지 않다’고 진단했다. 장애인 지하철 탑승 시위 중 여러 시민이 불편을 겪자 정치권은 ‘시민을 볼모 삼는다’며 갈등을 드러냈지만 시민 상당수는 시위의 배경을 이해하고, 이동권 보장에 공감하고 있었다.`}</p>
    <p>{`장애인 이동권 관련 갈등의 근본적 책임이 있는 집단으로는 가장 많은 응답자가 정치권(29.6%)과 정부(27.6%)를 꼽았다.`}</p>
    <h3>{`이동권 보장은 기본권… 정책에 장애 당사자 목소리 반영돼야`}</h3>
    <p>{`장애인과 비장애인 참가자 36명은 “장애인 이동권은 기본권인데도 날이 선 소수 의견이 다수처럼 보이는 게 안타까웠다”, “장애인과 비장애인으로 나누지 않고 이동권 보장에 힘써야 한다”고 입을 모았다. 휠체어를 이용하는 김강민씨는 “상대방을 설득해야 한다는 부담감을 느끼며 왔는데, 오히려 응원을 받고 가게 돼 든든하다”고 말했다. 특히 참여자의 42.9%는 ‘장애인 이동권 정책 개선에 당사자 의견이 제대로 반영되지 않는다’는 데 공감했다. 뇌병변 장애인 황지혜씨는 “나와 닮은 사람들, 비슷한 생각을 하는 사람들을 찾고 싶었기에 이 자리가 소중하다”면서 “오늘 나눈 이야기가 정책으로 반영됐으면 좋겠다”고 했다. 동시 제공된 문자 통역을 이용한 청각장애인 조은영씨는 “숙의토론 기회가 많아지고 장애인에게 널리 알려지기를 바란다”면서 “서로 도움을 주고받을 기회도 많아질 것”이라고 했다. `}</p>

    <h2>{`가장 심각한 장애인 이동권 문제`}</h2>
    <ChartBase type={'bar'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <h6>{`*서울신문은 숙의토론 전문기관 코리아스픽스가 2022년 6월 25일 장애인·비장애인 36명을 화상 연결해 장애인 이동권 문제에 대해 숙의토론한 자료를 바탕으로 작성되었습니다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      